<template>

  <div>

    <!-- Table Container Card -->

        <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
              >
                <span class="text-nowrap" @click="openItem">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              striped
              hover
              :fields="tableColumns"
              empty-text="未找到记录"
              v-model="tableData"
      >

        <!-- Column: Type -->
        <template #cell(add_time)="data">
          {{toDate((data.item.add_time))}}
        </template>

        <template #cell(memo)="data">
          <b-form-input
                  id="memo"
                  size="sm"
                  v-model="data.item.memo"
                  style="width:80px"
          />
        </template>

        <template #cell(attachments)="data">
          <div>
            <feather-icon icon="EditIcon" @click="showAttachments(data.item)"/>
            <attachment-upload :theme="'preview-dropdown'"
                               :attachment_id="'attachments'"
                               :id="data.item.attachments"
                               :object_type="'attachments'"
                               :object_id="0"
                               :readonly="true"
                               @change=""

            />
          </div>

        </template>

        <template #cell(loss_charge)="data">
          <ValidationProvider rules="twoDecimals" name="服务费" #default="{ errors }" >
            <b-form-input
                    id="loss_charge"
                    size="sm"
                    v-model="data.item.loss_charge"
                    type="number"
                    style="width:80px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>

        <template #cell(service_order_charge)="data">
          <b-form-input
                  id="service_order_charge"
                  size="sm"
                  v-model="data.item.service_order_charge"
                  type="number"
                  style="width:80px"
                  readonly
          />
        </template>



        <template #cell(fine_charge)="data">
          <b-form-input
                  id="fine_charge"
                  size="sm"
                  v-model="data.item.fine_charge"
                  type="number"
                  style="width:80px"
                  readonly
          />

        </template>

        <template #cell(share_charge)="data">
          <ValidationProvider rules="twoDecimals" name="共享仓扣款" #default="{ errors }" >
            <b-form-input
                    id="share_charge"
                    size="sm"
                    v-model="data.item.share_charge"
                    type="number"
                    style="width:80px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>

        <template #cell(tem_charge)="data">
          <ValidationProvider rules="twoDecimals" name="平台补款" #default="{ errors }" >
            <b-form-input
                    id="tem_charge"
                    size="sm"
                    v-model="data.item.tem_charge"
                    type="number"
                    style="width:80px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>


        <template #cell(other_charge)="data">
          <ValidationProvider rules="twoDecimals" name="其他扣款"  #default="{ errors }" >
            <b-form-input
                    id="other_charge"
                    size="sm"
                    v-model="data.item.other_charge"
                    type="number"
                    style="width:80px"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link>
            <feather-icon @click="saveItem(data.item)"
                          icon="SaveIcon"
                          size="16"
                          class="align-middle text-body"
            />
          </b-link>
        </template>

        <!-- Column: Actions -->

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <!--附件   hide-footer="true"-->
    <b-modal
            id="modal-attachments-receipt"
            centered
            ok-only
            ok-title="确认"
            size="lg"
            title="添加附件"
            ref="remarkModal"
            @ok="saveAttachments"
            @hidden="clearInput"
    >
      <b-row>

        <b-col cols="12">
          <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件"
                  label-for="attachments_ids"
                  label-size="sm"
                  class="mb-1"
          >
            <attachment-upload :theme="'files'"
                               :attachment_id="'attachments_ch'"
                               :id="attachmentsStr"
                               :object_type="'allot_outbound'"
                               :object_id="attachmentsObjectIdCh"
                               @change="onUploadedExcel"
            />
          </b-form-group>
        </b-col>


      </b-row>

    </b-modal>
    <!--添加明细-->
    <b-modal
            id="modal-fixed-item"
            centered
            ok-only
            ok-title="确认"
            size="lg"
            title="添加明细"
            ref="fixedItemModal"
            @ok="addItem"
    >
      <b-row>
          <b-col md="12" cols="12">
              <b-form-group
                      label-cols="1"
                      label-cols-lg="1"
                      label="日期"
                      label-for="attachments_ids"
                      label-size="sm"
                      class="mb-1"
              >
                <flat-pickr
                        v-model="searchTime"
                        class="form-control"
                        :config="{dateFormat: 'Y-m-d', mode:'multiple',enableTime: true,maxDate: toTime(nowTimeEndForMonth(mainAddTime)),minDate:toTime(nowTimeBeginForMonth(mainAddTime))}"

                />
              </b-form-group>
          </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber,addNumber,nowTimeBeginForMonth,nowTimeEndForMonth } from '@core/utils/filter'
import fixedchargeitemUseList from './fixedchargeitemUseList'
import fixedchargeitemStore from './fixedchargeitemStore'
import fixedchargeStore from "@/views/apps/fixedcharge/fixedchargeStore";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {useToast} from "vue-toastification/composition";
import XyInputButton from "@/views/components/xy/XyInputButton";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    XyInputButton
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('fixedchargeitem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    clearInput: function () {
      this.attachmentsStr = ''
      this.attachmentsObjectIdCh = ''
      this.$refs['refListTable'].refresh()
    },
  },
  props:{
    fixedChargeId:{
      type: String,
      default: '0'
    },
    mainAddTime:{
      type: Number,
      default: '0'
    },
  },
  data(){
    return {
      attachmentsStr: '',
      attachmentsObjectIdCh: '',
      attachmentsId: '',
      orderId:'',
      tableData:[],
      searchTime:""
    }
  },

  setup(props) {
    // Register module
    if (!store.hasModule('fixedchargeitem')) store.registerModule('fixedchargeitem', fixedchargeitemStore)
    if (!store.hasModule('fixedcharge')) store.registerModule('fixedcharge', fixedchargeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fixedchargeitem')) store.unregisterModule('fixedchargeitem')
      if (store.hasModule('fixedcharge')) store.unregisterModule('fixedcharge')
    })
    const toast = useToast()
    /**
     * 保存明细
     */
    const saveItem = async function (data) {
      let success = true
      let successOne = true
      let successTwo = true
      if(isNumber(data.other_charge)>0){
        const regExp = /^[0-9]+(.[0-9]{1,2})?$/
        success =  regExp.test(data.other_charge)
      }
      if(isNumber(data.share_charge)>0){
        const regExp = /^[0-9]+(.[0-9]{1,2})?$/
        successOne =  regExp.test(data.share_charge)
      }
      if(isNumber(data.loss_charge)>0){
        const regExp = /^[0-9]+(.[0-9]{1,2})?$/
        successTwo =  regExp.test(data.loss_charge)
      }

      if(success&&successOne&&successTwo){
        store.dispatch('fixedcharge/saveItem', {
          ...data
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('明细更新成功')
          } else {
            toast.error(res.data.data)
          }
          refetchData()
        })
      }else {
        toast.error('金额格式不正确!')
      }
    }


    const onUploadedExcel = function (id, attachment, result) {
      this.attachmentsId = result
    }

    const showAttachments = function (item) {
      this.orderId = item.item_id
      this.attachmentsObjectIdCh = item.item_id
      this.attachmentsStr = item.attachments
      this.$refs['remarkModal'].show()
    }

    const openItem = function () {
      this.$refs['fixedItemModal'].show()
    }

    const addItem = function () {
      let timeArr = this.searchTime.split(",")
      this.searchTime = ""
      if(timeArr.length>0){
         for(let i = 0 ; i < timeArr.length ; i ++){
            let time = timeArr[i]
              store.dispatch('fixedcharge/addFixedChargeItem', {
                id: props.fixedChargeId,
                salesTime:time
              }).then(res => {
                if (res.data.code === 0) {
                  toast.success('明细添加成功')
                  this.refetchData()
                } else {
                  toast.error(res.data.data)
                }
              })
         }
      }else{
        toast.error("最少选一个时间吧")
      }


    }



    /**
     * 单独更新附件
     */
    const saveAttachments = function () {
      store.dispatch('fixedcharge/addAttachments', {
        id: this.orderId,
        attachmentsId: this.attachmentsId,
      }).then(res => {
        if (res.data.code === 0) {
          toast.success('附件更新成功')
        } else {
          toast.error('附件更新失败')
        }
      })
    }




    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = fixedchargeitemUseList(
            {
              fixed_charge_id:props.fixedChargeId || 0
            }
    )

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      saveItem,
      onUploadedExcel,
      showAttachments,
      saveAttachments,
      isNumber,
      openItem,
      addItem,
      addNumber,
      nowTimeBeginForMonth,
      nowTimeEndForMonth,


    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
