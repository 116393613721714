<template>
  <b-card
    class="fixedcharge-edit-wrapper"
  >
    <!-- form -->
    <b-form id="fixedchargeForm" class="edit-form mt-2">
      <b-card header="基本信息" >
        <div class="card-body">
          <b-row>
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="平台名称"
                      label-for="channel_name"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-input
                        id="channel_name"
                        size="sm"
                        v-model="fixedcharge.channel_name"
                        readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="城市"
                      label-for="city"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-input
                        id="city"
                        size="sm"
                        v-model="fixedcharge.city"
                        readonly
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="店铺名称"
                      label-for="store_name"
                      label-size="sm"
                      class="mb-1"
              >
                <b-form-input
                        id="store_name"
                        size="sm"
                        v-model="fixedcharge.store_name"
                        readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card header="费用信息" >
        <div class="card-body">
          <fixed-charge-item-list :fixedChargeId="id" :mainAddTime="mainAddTime" ref="itemList"></fixed-charge-item-list>
        </div>
      </b-card>
      <b-row>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import fixedchargeStore from './fixedchargeStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isNumber} from '@core/utils/filter'
import FixedChargeItemList from "@/views/apps/app/fixedchargeitem/FixedChargeItemList";
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    FixedChargeItemList
  },
  data() {
    return {
      id: ref(0),
      fixedcharge: ref({}),
      mainAddTime:'',
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('fixedcharge')) store.registerModule('fixedcharge', fixedchargeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('fixedcharge')) store.unregisterModule('fixedcharge')
    })

    const edit = function() {
      store.dispatch('fixedcharge/edit', {id: this.id}).then(res => {
        this.fixedcharge = res.data.data
        this.mainAddTime = this.fixedcharge.add_time
      })
    }

    const view = function() {
      store.dispatch('fixedcharge/view', {id: this.id}).then(res => {
        this.fixedcharge = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      let dataInfo = this.$refs.itemList.tableData
      for(let i = 0; i< dataInfo.length ; i++){
        let success = true
        let successOne = true
        let successTwo = true
        if(isNumber(dataInfo[i].other_charge)>0){
          const regExp = /^[0-9]+(.[0-9]{1,2})?$/
          success =  regExp.test(dataInfo[i].other_charge)
        }
        if(isNumber(dataInfo[i].share_charge)>0){
          const regExp = /^[0-9]+(.[0-9]{1,2})?$/
          successOne =  regExp.test(dataInfo[i].share_charge)
        }
        if(isNumber(dataInfo[i].loss_charge)>0){
          const regExp = /^[0-9]+(.[0-9]{1,2})?$/
          successTwo =  regExp.test(dataInfo[i].loss_charge)
        }
        if(success&&successOne&&successTwo){
          continue ;
        }else{
          toast.success('请确保金额格式正确!')
        }


      }
      for(let i = 0; i< dataInfo.length ; i++){
        store.dispatch('fixedcharge/saveItem', {
          ...dataInfo[i]
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('明细更新成功')
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
