import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition (state) {
      return state.condition
    },
  },
  mutations: {
    updateCondition (state, condition) {
      state.condition = condition
    },
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/fixedcharge/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/fixedcharge/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/fixedcharge/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/fixedcharge/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/fixedcharge/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addAttachments (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/api/fixedcharge/addAttachments', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    saveItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/api/fixedcharge/saveItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    addFixedChargeItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/api/fixedcharge/addFixedChargeItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    addFixedCharge (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/api/fixedcharge/addFixedCharge', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    exportToExcel(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
              url: '/api/fixedcharge/exportToExcel',
              method:'get',
              params: params,
              responseType: 'blob',
            }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
